body {
  /*background: #9903ff;*/
  font-family: Roboto;
  font-weight: 400;
  overflow-y: auto;
}

#root {
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  position: relative;
}

h1 {
  font-size: clamp(34px, 5vw, 48px);
  font-weight: 400;
}
h2 {
  font-size: 2rem;
  font-weight: 400;
}
h3 {
  font-size: 1.25rem;
  font-weight: normal;
}

p,
label {
  white-space: pre-wrap;
}

h2 + h3,
p + h3,
h1 + p,
h2 + p,
ul + h3,
ul + p {
  margin-top: 24px;
}

h3 + p,
p + p {
  margin-top: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  padding-left: 2rem;
}
